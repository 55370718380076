import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  ...attributes\n  @direction='column'\n  @alignItems='center'\n  @width='full'\n  local-class='empty-container'\n  data-test-am-errorContainer\n>\n\n  {{#if @isHistoryTable}}\n    <AkSvg::SoxUnscannedHistoryTableEmpty\n      local-class='empty-state-vector'\n      data-test-am-errorIllustration\n    />\n  {{else}}\n    <AkSvg::SoxUnscannedDetailsTableEmpty\n      local-class='empty-state-vector'\n      data-test-am-errorIllustration\n    />\n  {{/if}}\n\n  <AkTypography\n    @variant='h5'\n    @gutterBottom={{true}}\n    data-test-am-emptyHeaderText\n  >\n    {{@header}}\n  </AkTypography>\n\n  <AkTypography local-class='body-text' data-test-am-emptyBodyText>\n    {{@body}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack\n  ...attributes\n  @direction='column'\n  @alignItems='center'\n  @width='full'\n  local-class='empty-container'\n  data-test-am-errorContainer\n>\n\n  {{#if @isHistoryTable}}\n    <AkSvg::SoxUnscannedHistoryTableEmpty\n      local-class='empty-state-vector'\n      data-test-am-errorIllustration\n    />\n  {{else}}\n    <AkSvg::SoxUnscannedDetailsTableEmpty\n      local-class='empty-state-vector'\n      data-test-am-errorIllustration\n    />\n  {{/if}}\n\n  <AkTypography\n    @variant='h5'\n    @gutterBottom={{true}}\n    data-test-am-emptyHeaderText\n  >\n    {{@header}}\n  </AkTypography>\n\n  <AkTypography local-class='body-text' data-test-am-emptyBodyText>\n    {{@body}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/storeknox/inventory-details/unscanned-version/table-empty/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/unscanned-version/table-empty/index.hbs"}});
import Component from '@glimmer/component';

interface StoreknoxInventoryDetailsUnscannedVersionTableEmptySignature {
  Element: HTMLElement;
  Args: {
    header?: string;
    body?: string;
    isHistoryTable?: boolean;
  };
}

export default class StoreknoxInventoryDetailsUnscannedVersionTableEmptyComponent extends Component<StoreknoxInventoryDetailsUnscannedVersionTableEmptySignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::InventoryDetails::UnscannedVersion::TableEmpty': typeof StoreknoxInventoryDetailsUnscannedVersionTableEmptyComponent;
  }
}
