import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  ...attributes\n  @direction='column'\n  @alignItems='center'\n  @justifyContent='center'\n  local-class='loading'\n  data-test-am-loading-container\n>\n  <AkSvg::EmptyLoadingList\n    local-class='loading-state-vector'\n    data-test-am-loading-illustration\n  />\n\n  <AkStack @alignItems='center' @spacing='1.5' class='mt-2'>\n    <AkLoader @size={{19}} data-test-am-loading-spinner />\n\n    {{#if @loadingText}}\n      <AkTypography local-class='loading-text'>\n        {{@loadingText}}\n      </AkTypography>\n    {{/if}}\n  </AkStack>\n</AkStack>", {"contents":"<AkStack\n  ...attributes\n  @direction='column'\n  @alignItems='center'\n  @justifyContent='center'\n  local-class='loading'\n  data-test-am-loading-container\n>\n  <AkSvg::EmptyLoadingList\n    local-class='loading-state-vector'\n    data-test-am-loading-illustration\n  />\n\n  <AkStack @alignItems='center' @spacing='1.5' class='mt-2'>\n    <AkLoader @size={{19}} data-test-am-loading-spinner />\n\n    {{#if @loadingText}}\n      <AkTypography local-class='loading-text'>\n        {{@loadingText}}\n      </AkTypography>\n    {{/if}}\n  </AkStack>\n</AkStack>","moduleName":"irene/components/storeknox/inventory-details/unscanned-version/table-loading/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/unscanned-version/table-loading/index.hbs"}});
import Component from '@glimmer/component';

interface StoreknoxInventoryDetailsUnscannedVersionTableLoadingSignature {
  Element: HTMLElement;
  Args: {
    loadingText?: string;
  };
}

export default class StoreknoxInventoryDetailsUnscannedVersionTableLoadingComponent extends Component<StoreknoxInventoryDetailsUnscannedVersionTableLoadingSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::InventoryDetails::UnscannedVersion::TableLoading': typeof StoreknoxInventoryDetailsUnscannedVersionTableLoadingComponent;
  }
}
