export default {
  "ak-divider-root": "_ak-divider-root_dmtdmy",
  "ak-divider-direction-horizontal": "_ak-divider-direction-horizontal_dmtdmy",
  "ak-divider-direction-vertical": "_ak-divider-direction-vertical_dmtdmy",
  "ak-divider-variant-fullWidth": "_ak-divider-variant-fullWidth_dmtdmy",
  "ak-divider-variant-middle": "_ak-divider-variant-middle_dmtdmy",
  "ak-divider-color-light": "_ak-divider-color-light_dmtdmy",
  "ak-divider-color-dark": "_ak-divider-color-dark_dmtdmy",
  "ak-divider-variant-vertical": "_ak-divider-variant-vertical_dmtdmy"
};
